import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"
import JobsBanner from "../components/jobs-banner"
import { RichText } from "../api/contentful"
import {
  Section,
  BlockHeading,
  Pagehead,
  PageTitle,
  OpenerImage,
  Breadcrumbs,
  Narrow,
} from "../components/shared"
import { Cooperation, Method, Tree } from "../components/illustrations"

const Grid = styled.section`
  display: grid;
  grid-column: 2;
  grid-template-columns: 1fr;
  gap: 2em;
  padding-bottom: 5em;
  @media only screen and (max-width: 800px) {
    padding: 0 0em 5em 0em;
  }
  @media only screen and (max-width: 666px) {
    grid-template-columns: 1fr;
    max-width: 80%;
  }
  @media only screen and (max-width: 480px) {
    max-width: 100%;
  }
`

const IllustrationWrapper = styled.div`
  flex: 1;

  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const FlexRowText = styled.div`
  flex: 1;
  font-size: 1.2rem;
  line-height: 1.2;
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2em; /* TODO: only supported in Firefox */
  width: 100%;

  &:nth-child(odd) {
    flex-direction: row-reverse;
  }

  @media only screen and (max-width: 666px) {
    flex-direction: column;
    gap: 0;

    &:nth-child(odd) {
      flex-direction: column;
    }
  }
`

const Leitbild = ({ data }) => {
  const pageData = data.contentfulMissionPage

  return (
    <Layout>
      <SEO title={pageData.title} description={pageData.metaDescription} />
      <Pagehead bottomMargin>
        <OpenerImage fluid={pageData.openerImage.fluid} alt="" />
        <PageTitle>
          <RichText node={pageData.pageTitle} unwrapParagraphs />
        </PageTitle>
        <Breadcrumbs nodes={[{ title: "Leitbild" }]} />
      </Pagehead>
      <Section>
        <Narrow>
          <Grid>
            <FlexRow>
              <IllustrationWrapper>
                <Tree height="15em" />
              </IllustrationWrapper>
              <FlexRowText>
                <RichText node={pageData.text1} h3Component={BlockHeading} />
              </FlexRowText>
            </FlexRow>
            <FlexRow>
              <IllustrationWrapper>
                <Cooperation height="15em" />
              </IllustrationWrapper>
              <FlexRowText>
                <RichText node={pageData.text2} h3Component={BlockHeading} />
              </FlexRowText>
            </FlexRow>
            <FlexRow>
              <IllustrationWrapper>
                <Method height="15em" />
              </IllustrationWrapper>
              <FlexRowText>
                <RichText node={pageData.text3} h3Component={BlockHeading} />
              </FlexRowText>
            </FlexRow>
          </Grid>
        </Narrow>
      </Section>
      <JobsBanner />
      <Contact />
    </Layout>
  )
}

export default Leitbild

export const query = graphql`
  query LeitbildQuery {
    contentfulMissionPage {
      title
      metaDescription
      openerImage {
        fluid {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      pageTitle {
        json
      }
      text1 {
        json
      }
      text2 {
        json
      }
      text3 {
        json
      }
    }
  }
`
